import { Pipe, PipeTransform } from '@angular/core';
import { Upa } from '../../upa-v2/upa.interface';

@Pipe({
  name: 'upaIsMissingTranslations'
})
export class UpaIsMissingTranslationsPipe implements PipeTransform {

  transform(upa: Upa, languageCode): boolean {
    return !upa.translations[languageCode]?.headline || !upa.translations[languageCode]?.description || this.isMissingCaption(upa, languageCode); 
  }

  private isMissingCaption(upa: Upa, languageCode: string): boolean {
    if (upa.photo_count > 0 && upa.photos.some(photo => !photo.caption[languageCode])) {
      return true;
    };
    if (upa.video_count > 0 && upa.videos.some(video => !video.caption[languageCode])) {
      return true;
    }
    if (upa.tour_count > 0 && upa.tours.some(tour => !tour.caption[languageCode])) {
      return true;
    }
    return false;
  }

}