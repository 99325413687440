import { Pipe, PipeTransform } from '@angular/core';
import { Brand } from '@hub-services/interfaces/brand';

@Pipe({
  name: 'getBrandNames'
})
export class GetBrandNamesPipe implements PipeTransform {

  transform (cabinTargeting: {cabin_id: number, brand_ids: number[]}[], brands: Brand[]): string[] {
    return  cabinTargeting.find(cabinTarget => cabinTarget.brand_ids.length > 0).brand_ids
      .map(brandId => brands.find(brand => brand.id === brandId)?.name);
  }
}